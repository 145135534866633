import { Box, Img, Text } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { ReactComponent as XIcon } from 'icons/x.svg'
import { ReactComponent as GTDIcon } from 'icons/gtd.svg'
import { logError } from 'utils/logger'

const GameCard = ({ hideBack, gtd, grayScaled, swapSelected, placed, flipped, small, player, selected, onSelect, disabled, index, width }) => {
  const [stared, setStared] = useState(disabled)
  const [imgSrc, setImgSrc] = useState('')
  
  useEffect(
    () => {
      setTimeout(
        () => {
          setStared(true)
        },
        500
      )
    },
    []
  )

  
  const trans = useMemo(
    () => {
      if (index === 0) {
        return `translate(0, 0)`
      }
      if (index === 1) {
        return `translate(${-(width / 2) + 68}px, 0)`
      }
      if (index === 2) {
        return `translate(${-(width / 2) - 52}px, 0)`
      }
      if (index === 3) {
        return `translate(-59px, -168px)`
      }
      if (index === 4) {
        return `translate(-178px, -168px)`
      }
      return
    },
    []
  )

  const imgUrl = player.headshot_webp || player.headshot
  
  useEffect(
    () => {
      setImgSrc('')
      if (imgUrl) {
        const img = new Image()
        img.src = imgUrl
        
        img.onload = () => {
          setImgSrc(imgUrl)
        }

        img.onerror = err => {
          logError('Image not found', {
            src: imgUrl,
            errorMessage: err && (err.message || err),
            player: player.rapid_id
          })
        }

        return () => {
          img.onload = null
          img.onerror = null
        }
      }
    },
    [imgUrl]
  )
  
  return (
    <Box
      position="relative"
      width={small ? "55px" : "108px"}
      height={small ? "80px" : "157px"}
      // animation={`${anim} 0.8s cubic-bezier(0.68, -.55, .265, 1.50) ${(4 - index) * 0.3}s forwards`}
      transition={hideBack ? 'initial' : "transform 0.8s cubic-bezier(0.68, -.55, .265, 1.50)"}
      transform={placed ? 'translate(0, 0)' : trans}
      transitionDelay={hideBack ? 'initial' : `${0.3 * (4 - index)}s`}
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        onClick={stared ? onSelect : undefined}
        transition={hideBack ? 'initial' : "transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275)"}
        transitionDelay={hideBack ? 'initial' : `${0.1 * index}s`}
        transform={hideBack ? 'initial' : (flipped? 'rotateY(0)' : "rotateY(180deg)")}
        sx={hideBack ? {} : {
          transformStyle: 'preserve-3d'
        }}
      >
        <Box
          data-player={player.rapid_id}
          w="100%"
          height="100%"
          position="absolute"
          top="0"
          left="0"
          key={(selected || disabled) ? 'selected' : 'unselected'}
          boxShadow="0px 1px 0px 0px rgba(0, 0, 0, 0.20)"
          bg="white"
          borderRadius={small ? "4px" : "6px"}
          px="2px"
          pt="2px"
          sx={hideBack ? {} : {
            backfaceVisibility: 'hidden',
          }}
        >
          <Box
            position="relative"
            h={small ? "72px" : "140px"} 
          >
            <Box
              backgroundColor={`#${player.team_color}`}  
              overflow="hidden"
              borderRadius={small ? "2px" : "4px"}
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              filter={(selected || !grayScaled) ? 'grayscale(0)' : 'grayscale(100%)'}
              sx={hideBack ? {}: {
                WebkitFilter: (selected || !grayScaled) ? 'grayscale(0)' : 'grayscale(100%)'
              }}
            >
              <Box 
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                backgroundImage={`url(${player.team_logo})`}
                backgroundPosition="center center"
                backgroundSize="cover"
                backgroundRepeat="no-repeat"
                opacity="0.3"
              />
              
              <Box
                position="relative"
                w="100%"
                h="100%"
              >
                {imgSrc && (
                  <Img
                    src={imgSrc}
                    w="100%"
                    h="100%"
                    objectFit="cover"
                    objectPosition="center"
                  />
                )}
              </Box>
              <Box 
                position="absolute"
                h="33%"
                left="0"
                right="0"
                bottom="0"
                background="linear-gradient(0deg, var(--chakra-colors-dark60) 0%, transparent 100%)"
              />
              <Box 
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                background="url(/images/texture.png)"
              />
           
              {!small && (
                <>
                  {!gtd && (<Text
                    position="absolute"
                    fontFamily="'Fugaz One', cursive"
                    fontSize="12px"
                    fontWeight="400"
                    top="2px"
                    left="5px"
                    textShadow="-1px 1px 0px rgba(0, 0, 0, 0.20)"
                  >
                    {player.jersey_number}
                  </Text>)}
                  <Box 
                    position="absolute"
                    w="16px"
                    h="16px"
                    right="3px"
                    top="3px"
                    borderRadius="16px"
                    bg={`url(${player.team_logo})`}
                    backgroundPosition="center center"
                    backgroundSize="cover"
                  />
                  <Text
                    position="absolute"
                    bottom="4px"
                    left="0"
                    right="0"
                    textAlign="center"
                    fontFamily="'Fugaz One', cursive"
                    color="white"
                    fontSize="12px"
                    fontWeight="400"
                    textTransform="uppercase"
                    lineHeight="1"
                    textShadow="-0.5px 0.5px 0px rgba(0, 0, 0, 0.25)"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    px="4px"
                  >
                    {player.last_name}
                  </Text>
                </>
              )}
              
              
            </Box>
            {!small && (
              <Text
                display="flex"
                height="11px"
                pt="4px"
                alignItems="center"
                justifyContent="center"
                fontFamily="'Bebas Neue', sans-serif"
                color="rgba(20, 20, 20, 0.90)"
                fontSize="10px"
                fontWeight="400"
                textTransform="uppercase"
                lineHeight="1"
                letterSpacing="0.8px"
                position="absolute"
                left="0"
                right="0"
                bottom="-11px"
              >
                {player.position}
              </Text>
            )}

            {gtd && (<Box
              position="absolute"
              top="3px"
              left="3px"
            >
              <GTDIcon />
            </Box>)}
          </Box>

          {swapSelected && (
            <Box
              position="absolute"
              top="22px"
              left="50%"
              transform="translateX(-50%)"
            >
              <XIcon />
            </Box>
          )}
        </Box>
        {!hideBack && (
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            background="url(/images/card.webp)"
            backgroundSize="100% 100%"
            backgroundRepeat="no-repeat"
            transform="rotateY(180deg)"
            sx={{
              backfaceVisibility: 'hidden'
            }}
          />
        )}
      </Box>
    </Box>
  )
}

export default GameCard