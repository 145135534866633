import { Box, Button, Flex } from '@chakra-ui/react'
import GameCard from 'components/game/GameCard'
import { useCallback, useState } from 'react'
import { usePlayersListQuery } from 'store/services/api'
import * as htmlToImage from 'html-to-image';

const PlayersListPage = () => {  
  const [type, setType] = useState('nba')
  const { data: players, error, isLoading, isFetching, isError } = usePlayersListQuery(type)
  

  const onSave = useCallback(
    e => {
      const { id, name, team  } = e.target.dataset
      
      const elem =  document.querySelector(`[data-player="${id}"]`)

      if (elem) {
        htmlToImage.toPng(elem).then(function(dataUrl) {
          var link = document.createElement('a');
          link.href = dataUrl;
          link.download = `${name} - ${team}.png`;

          document.body.appendChild(link);

          link.click();
          document.body.removeChild(link);
        }).catch(err => {
          console.log(err)
        });
      }
    },
    []
  )

  if (isLoading || isFetching) return null;
  if (isError) {
    console.error(error)
    return null;
  }
  
  return (
    <Box>
      <Flex
        position="relative"
        bg="var(--chakra-colors-white10)"
        alignItems="stretch"
        height="37px"
        borderRadius="32px"
        mt="15px"
        w="270px"
        mx="auto"
      >
        
        <Flex
          onClick={() => setType('nba')}
          flex="1"
          alignItems="center"
          justifyContent="center"
          borderRadius="32px"
          bg={type === 'nba' ? 'var(--chakra-colors-white40)' : 'transparent'}
          cursor="pointer"
        >
          NBA
        </Flex>
          
        <Flex 
          flex="1"
          onClick={() => setType('mlb')}
          alignItems="center"
          justifyContent="center"
          borderRadius="32px"
          bg={type === 'mlb' ? 'var(--chakra-colors-white40)' : 'transparent'}
          cursor="pointer"
        >
          MLB
        </Flex>
        <Flex 
          flex="1"
          onClick={() => setType('nfl')}
          alignItems="center"
          justifyContent="center"
          borderRadius="32px"
          bg={type === 'nfl' ? 'var(--chakra-colors-white40)' : 'transparent'}
          cursor="pointer"
        >
          NFL
        </Flex>
      </Flex>

      <Flex
        flexWrap="wrap"
        rowGap="16px"
        columnGap="4px"
        justifyContent="center"
        mt="20px"
      >
        {players.map(p => (
          <Box key={p.rapid_id}>
            <GameCard player={p} placed flipped hideBack />
            <Flex justifyContent="center" mt="6px">
              <Button 
                data-id={p.rapid_id} 
                data-name={p.full_name} 
                data-team={p.team_name}
                onClick={onSave} 
                size="sm" 
                variant="blue" 
                h="30px"
              >
                Download
              </Button>
            </Flex>
          </Box>
        ))}
      </Flex>
    </Box>
  )
}

export default PlayersListPage


